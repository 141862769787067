<template>
  <div class="info-popup" v-if="visible">
    {{ message }}
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    }
  }
};
</script>

<style>
.info-popup {
  position: absolute;
  background: var(--Dark-Grayscale-Light-Gray, #212123);
  border: 1px solid #FFF;
  border-radius: 8px;
  opacity: 0.9;
  backdrop-filter: blur(6px);
  color: #FFF;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 200;
  overflow: visible;
}
</style>