<template>
<div id="forecast-bar">
  <marquee behavior="scroll" direction="left" scrollamount="5">

    <div v-if="activeWeatherAlertsDescription.length" class="alerts-container">
      <div>
        <AlertIcon />
      </div>
      <div class="global-text-l-regular text-white">
        {{ activeWeatherAlertsHeadline }}: {{ activeWeatherAlertsDescription }}
      </div>
    </div>
  </marquee>
  
  <div class="forecast-date-container">
    <div class="forecast-container">
      <div class="current-date-value global-text-m-regular">{{ formattedDate }}</div>
      <ClearSkyIcon class="forecast-icon" v-if="showClearSky" />
      <FewCloudsIcon class="forecast-icon" v-if="showFewClouds" />
      <ScatteredCloudsIcon class="forecast-icon" v-if="showScatteredClouds" />
      <BrokenCloudsIcon class="forecast-icon" v-if="showBrokenClouds" />
      <ShowerRainIcon class="forecast-icon" v-if="showShowerRain" />
      <RainIcon class="forecast-icon" v-if="showRain" />
      <ThunderstormIcon class="forecast-icon" v-if="showThunderstorm" />
      <SnowIcon class="forecast-icon" v-if="showSnow" />
      <MistIcon class="forecast-icon" v-if="showMist" />
    </div>

    <div class="weather-data-container">
      <div class="forecast-container" :title="forecastDescription">
        <div class="current-temperature-container">
          <div class="global-text-detail-regular">AIR TEMPERATURE: {{ temperature }} °F</div>
        </div>
      </div>
      <div class="forecast-container" :title="humidityDescription">
        <div class="current-humidity-container">
          <div class="global-text-detail-regular">RELATIVE HUMIDITY: {{ humidity }} %</div>
        </div>
      </div>
      <div class="forecast-container" :title="windSpeedDescription">
        <div class="current-humidity-container">
          <div class="global-text-detail-regular">WIND SPEED: {{ windSpeed }} mph</div>
        </div>
      </div>
      <div class="forecast-container" :title="windDirectionDescription">
        <div class="current-humidity-container">
          <div class="global-text-detail-regular">WIND DIRECTION: {{ windDirection }}°</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import ClearSkyIcon from '../assets/svg/clearSkyIcon.vue';
import FewCloudsIcon from '../assets/svg/fewCloudsIcon.vue';
import ScatteredCloudsIcon from '../assets/svg/scatteredCloudsIcon.vue';
import BrokenCloudsIcon from '../assets/svg/brokenCloudsIcon.vue';
import ShowerRainIcon from '../assets/svg/showerRainIcon.vue';
import RainIcon from '../assets/svg/rainIcon.vue';
import ThunderstormIcon from '../assets/svg/thunderstormIcon.vue';
import SnowIcon from '../assets/svg/snowIcon.vue';
import MistIcon from '../assets/svg/mistIcon.vue';
import AlertIcon from '../assets/svg/alertIcon.vue';

export default {
  name: 'WeatherBar',
  components: {
    ClearSkyIcon,
    FewCloudsIcon,
    ScatteredCloudsIcon,
    BrokenCloudsIcon,
    ShowerRainIcon,
    RainIcon,
    ThunderstormIcon,
    SnowIcon,
    MistIcon,
    AlertIcon,
},
  data() {
    return {
      currentDate: new Date(),
      weather: {},
      temperature: '',
      humidity: '',
      windSpeed: '',
      showClearSky: false,
      showFewClouds: false,
      showScatteredClouds: false,
      showBrokenClouds: false,
      showShowerRain: false,
      showRain: false,
      showThunderstorm: false,
      showSnow: false,
      showMist: false,
      forecastDescription: '',
      weatherAlertsDescription: [],
      activeWeatherAlertsHeadline: [],
      activeWeatherAlertsDescription: [],
    }
  },
  computed: {
    formattedDate() {
      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      return this.currentDate.toLocaleDateString(undefined, options);
    }
  },
  methods: {
    getMetricWeatherData() {
      axios.get('https://api.openweathermap.org/data/2.5/weather?lat=40.753742&lon=-73.983559&limit=1&appid=81258d9035cc0dc749fca60418f5e1ce')
        .then(response => {
          const kelvinTemp = response.data.main.temp;
          const fahrenheitTemp = (kelvinTemp - 273.15) * 9/5 + 32;
          this.temperature = fahrenheitTemp.toFixed(0);
          this.forecast = response.data;
          this.humidity = response.data.main.humidity;
          //console.log('Current Forecast:', this.forecast);
          // console.log(this.temperature);
        }).catch(error => {
          console.log('Error:', error);
        })
    },
    getImperialWeatherData() {
      axios.get('https://api.openweathermap.org/data/2.5/weather?lat=40.753742&lon=-73.983559&units=imperial&limit=1&appid=81258d9035cc0dc749fca60418f5e1ce')
        .then(response => {
          this.forecast = response.data;
          this.forecastDescription = this.forecast.weather[0].description;
          this.windSpeed = this.forecast.wind.speed;
          this.windDirection = this.forecast.wind.deg;

          let weatherId = this.forecast.weather[0].id;
          // let description = this.forecast.weather[0].description;
          // console.log("weather ID:", weatherId);
          // console.log("description:", description);
          
          if (weatherId == "800") {
            this.showClearSky = true;
          } else if (weatherId >= 801 && weatherId < 803) {
            this.showFewClouds = true;
          } else if (weatherId >= 701 && weatherId < 782) {
            this.showMist = true;
          } else if (weatherId >= 600 && weatherId < 623) {
            this.showSnow = true;
          } else if (weatherId >= 500 && weatherId < 532) {
            this.showRain = true;
          } else if (weatherId >= 300 && weatherId < 322) {
            this.showShowerRain = true;
          } else if (weatherId >= 200 && weatherId < 233) {
            this.showThunderstorm = true;
          } else if (weatherId >= 803 && weatherId < 805) {
            this.showBrokenClouds = true;
          }
        }).catch(error => {
          console.log('Error:', error);
        })
    },
    getActiveWeatherAlerts() {
      axios.get('https://api.weather.gov/alerts/active?zone=NYZ072')
        .then(response => {
          this.activeWeatherAlerts = response.data.features[0];
          this.activeWeatherAlertsDescription = this.activeWeatherAlerts.properties.description;
          this.activeWeatherAlertsHeadline = this.activeWeatherAlerts.properties.headline;
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.getMetricWeatherData();
    this.getImperialWeatherData();
    // this.getWeatherAlerts();
    this.getActiveWeatherAlerts();
  }
}
</script>

<style lang="scss" scoped>
.forecast-date-container {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.forecast-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.forecast-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-left: 0.5rem;
}

.weather-data-container {
  color: #FFF;
  font-family: "Roboto Mono";
  text-align: right;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  font-variant: small-caps;
  letter-spacing: 1px;
  margin-top: 1rem;
}

.current-temperature-container,
.current-humidity-container {
  margin-top: 0.2rem;
  text-align: right;
  margin-left: auto;
}

#forecast-bar {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  gap: 5rem;
  width: calc(100vw - 448px - 6rem);
  gap: 2rem;
}

.alerts-container {
  display: flex;
  align-items: flex-start;
}
</style>