<template>
  <div v-if="showPointTemperatureBar === true" id="point-temperature-bar">
    
    <div class="forecast-date-container">
      <div class="forecast-container" :title="forecastDescription">
        
        <div class="current-temperature-container frosted-glass" style="padding: 20px; display: flex; border-radius: 10px">
          <div style="padding-right: 20px">
            <div v-for="(time, index) in singlePointTimesWithTemperatures" :key="time + index">
              <div v-if="time[1] === '#22D976'" style="width: 8px; height: 8px; display: inline-block; background-color: #22D976;"></div>
              <div v-if="time[1] === '#34A6FA'" style="width: 8px; height: 8px; display: inline-block; background-color: #0000FA;"></div>
              <div v-if="time[1] === '#EE3636'" style="width: 8px; height: 8px; display: inline-block; background-color: #EE3636;"></div>
              <div style="display: inline-block; padding-left: 12px" >{{ time[0].split('-')[1].replace(/(\d{2})(\d{2})/, '$1:$2') }}</div>
            </div>
          </div>
          <div class="self-start">
            <b-btn @click="closeToolTips" class="temperature-time-close" style="">X</b-btn>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'pointforcast',
    components: {
  },
    data() {
      return {
        currentDate: new Date(),
        weather: {},
        forecastDescription: '',
      }
    },
    computed: {
      ...mapGetters({
      'singlePointTimesWithTemperatures': "getSinglePointTimesWithTemperatures",
      'showPointTemperatureBar': "getShowPointTemperatureBar"
      }),
      formattedDate() {
        const options = { weekday: 'long', month: 'long', day: 'numeric' };
        return this.currentDate.toLocaleDateString(undefined, options);
      }
    },
    methods: {
      closeToolTips() {
        this.$store.commit('setShowPointTemperatureBar', false)
        this.$emit('remove-map-marker')
      }
    },
    mounted() {
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .forecast-date-container {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    white-space: nowrap;
  }
  .forecast-container {
    display: flex;
  }
  
  #forecast-bar {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    gap: 5rem;
    // margin-left: calc(448px + 1.5rem)
    width: calc(100vw - 448px - 6rem);
    gap: 2rem;
  }
  
  .alerts-container {
    display: flex;
    align-items: flex-start;
    // gap: 10rem;
  }
  </style>