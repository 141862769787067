<template>
  <div id="sidebar">
    <div id="sidebar-container" class="text-left frosted-glass" :class="{'mobile': mobileFlag, 'desktop': mobileFlag == false}">
      <div class="container p-4">
        <div class="row" v-if="mobileFlag === false">
          <div class="col-12 d-inline-flex flex-column">
            <div class="desktop-heading-m text-white" @click="testGather">Comfort NYC</div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <p class="global-text-m-regular text-white" @click="swapTheData">
              Comfort NYC is a web tool that analyzes and visualizes the microclimate of Bryant Park in New York City. Utilizing daily weather forecast data and microclimate simulation, it provides real-time insights into the most comfortable areas of the park outdoor activities.
            </p>
          </div>
        </div>

        

        <div class="row mb-4" v-for="(metric, i) in models" :key="i">
          <div class="col-12">
            <switch-button
              v-model="metric.toggle"
              :dataName="metric.dataName"
              class="desktop-heading-xs text-white"
              @toggle="selectMetric(metric)"
              ><div class="label-icon-container">{{ metric.label }} <infoIcon :message="metric.description"/></div>
            </switch-button>


            <div class="expand" v-if="metric.toggle">
              <p class="mt-2 global-text-m-regular text-white">
                {{metric.tagline}}
              </p>
              <div class="row">
                <time-slider></time-slider>
              </div>
              <div v-if="metric.userInputRequired">
                <p v-if="metric.dataName === 'comfortLevel'" class="mt-2 text-white mb-4 italic text-xs font-medium leading-4">
                  At what 'feels like' temperature do you feel comfortable?
                </p>
                <p v-if="metric.dataName === 'comfortDuration'" class="mt-2 text-white mb-4 italic text-xs font-medium leading-4">
                  What do you consider comfortable?
                </p>
                <div v-if="metric.dataName === 'comfortDuration'" class="comfort-levels">
                  <div class="comfort-level">SLIGHT COLD STRESS</div>
                  <div class="comfort-level">NO THERMAL STRESS</div>
                  <div class="comfort-level">MODERATE HEAT STRESS</div>
                  <div class="comfort-level">STRONG HEAT STRESS</div>
                </div>
                <comfort-slider></comfort-slider>
                <br>
              </div>

              <div class="metric-options mt-2" v-if="metric.toggle">
                <colorKey :dataType="metric.dataName"></colorKey>
              </div>
            </div>
          </div>
        </div>
        
        <hr class="mb-4">

        <div class="row mb-2">
          <div class="col-12 text-center">
            <b-btn class="block col-4" @click="aboutClick">
              <div class="global-text-m-regular">
                About
              </div>
            </b-btn>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <div class='text-muted global-text-s-regular'>
              beta v. {{version}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="mobileFlag" class="mobile-bar d-flex justify-content-around align-items-center">
      <div class="mobile-toggles">
        <div class="d-flex justify-content-center align-items-center">
          <mobileIcons :iconType="'tool'" :active="sidebarOpen" />
        </div>
        <div class="subtitle">{{toggle.name}}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// vue slider import
import 'vue-slider-component/theme/default.css'

// switch button template
import switchButton from '@/components/switchButton.vue'

// time slider template
import timeSlider from '@/components/timeSlider.vue'
import comfortSlider from '@/components/comfortSlider.vue'
import colorKey from '@/components/colorKey.vue'
import infoIcon from '../assets/svg/infoIcon.vue'

import {models, options} from '@/assets/template/templates.js'

export default {
  name:'sidebar',
  components:{
    switchButton, timeSlider, colorKey, comfortSlider, infoIcon
},
  props:{},
  data(){
    return {
      sidebarOpen: true,
      options: options,
      models: models,
      activeOption: null,
      activeModel: null,
      toggleInfo: false,
    }
  },
  computed:{
    ...mapGetters({
      'mobileFlag': "getMobileFlag",
      'version': 'getVersion',
      'setting': 'getSetting',
      'userMinMaxComfort': 'getUserMinMaxComfortUTI'
    }),
  },
  mounted () {
    console.log('the min max comfort from the store', this.userMinMaxComfort)
  },
  methods:{
    aboutClick(){
      this.$store.commit("setAboutToggle", true)
    },
    updateSlider (metric) {
      this.$emit('setMapFilter', metric[this.setting].values, metric[this.setting].max, metric[this.setting].min)
    },
    updateLayer () {
      let layer = null

      if(this.activeOption !== null) {
        this.$emit('setActiveOption', this.activeOption)
      }

      if(this.activeOption !== null && this.activeModel !== null) {
        let key = this.activeModel.dataName
        let tier = this.activeModel.option === true ? 'results' : 'change'

        this.$store.commit('setSetting', `${tier}_settings`)
        layer = this.activeModel[key][this.activeOption][tier]

        this.$store.dispatch('readMeshData', layer)
      } else {
        this.$store.dispatch('readMeshData', null)
      }

    },
    selectMetric(metric) {
      this.activeModel = null
      this.models.forEach((d) => {
        if(d.dataName !== metric.dataName){
          d.toggle = false
        } else {
          if(d.toggle === true) {
            this.$store.commit('setScale', metric.dataName)
          } else {
            this.$store.commit('setScale', 'comfortBreakdown')
            this.models.find((d) => d.dataName === 'comfortBreakdown').toggle = true
          }
        }
      })
    },
    sliderError(err) {
      if(err) {
        console.error(err)
      }
    },
    testGather() {
      this.$store.dispatch('startGatheringData')
    },
    swapTheData() {
      this.$store.dispatch('swapData')
    },
  }
}
</script>

<style lang="scss">
// $legend-size: 20px;

// .spacer-sm {
//   height: 4rem;
// }

.btn-group {
  width: 100%;

  .btn {
    margin: 0 0.5rem;
  }
}
.vue-slider-rail {
  background: transparent!important;
}
.anchor-highlight{
  color: #007bff;

  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
}
.open-street-line{
  height: 5px;
  width: 75px;

  background: #3ad3ad;
}
ul{
  list-style: none;
}
.color-bubble{
  height: 15px;
  width: 15px;

  border-radius: 50%;

  display: inline;

  margin-right: 5px;
}
.mobile-bar{
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: $black;

  padding: 1rem;

  z-index: 10;

  border-top: 1px solid $dark-grey;

  pointer-events: none;
}

// SIDEBAR
#sidebar-container{
  position: absolute;
  left: 0;

  // background: $black;
  // background: transparent;
  overflow-y: scroll!important;
  overflow-x: visible!important;

  &.mobile{
    bottom: 80px;
    width: 100%;
    height: calc(50vh - 80px);
  }
  &.desktop{
    top: 0px;
    width: 448px;
    height: 100vh;
  }
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
// SLIDER OPTIONS
.vue-slider-mark{
  background-color: $white;
  border-radius: 50%;
  font-family: 'Roboto Mono', monospace;
  opacity:0.75;
  line-height: 1.33;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-variant: small-caps;
  letter-spacing: 1px;
  font-size: 11px;
}
.vue-slider-mark-label{
  font-family: 'Roboto Mono', monospace;
}

.metric-gradient-parent{
  padding: 0 15px;
}
.metric-gradient{
  border-radius: 5px;
  height: 15px;
}
.label-icon-container {
  display: flex;
  align-items: center;
}
.label-icon-container info-icon {
  margin-left: 8px; /* Adjust the spacing as needed */
}
.comfort-levels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px; /* Add space below comfort-levels */
    padding-bottom: 15px;
  }
  
  .comfort-level {
    width: 12.5%;
    height: 16px;
    display: inline-block;
    text-align: center;
    font-family: 'Roboto Mono', monospace;
    opacity: 0.5;
    font-size: 8.5px;
  }

</style>
