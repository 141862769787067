import store from "../../store.js"

// const store.getUserMinComfortTemp = store.state.userMinComfortTemp // 9
// const HOTTEST_COMFORTABLE_TEMPERATURE = store.state.userMaxComfortTemp // 26

export function comfortDuration(spotToSit) {
  let timesToStartSitting = Object.entries(spotToSit["properties"]).filter(
    (entry) => entry[0].startsWith("hr-")
  )
  timesToStartSitting.forEach((timeAndTemp) => {
    let time = timeAndTemp[0]
    let comparisonTemps = tempsToCompare(time, spotToSit)
    let duration = durationOfComfortCondition(comparisonTemps)
    spotToSit["properties"][`durationSituation-${time}`] = duration
  })
}

function durationOfComfortCondition(comparisonTemps) {
  if (comparisonTemps[0] !== comparisonTemps[15]) {
    return `15-${comparisonTemps[0]}`
  } else if (comparisonTemps[0] !== comparisonTemps[30]) {
    return `30-${comparisonTemps[0]}`
  } else if (comparisonTemps[0] !== comparisonTemps[45]) {
    return `45-${comparisonTemps[0]}`
  } else {
    return `60-${comparisonTemps[0]}`
  }
}

function tempsToCompare(time, spotToSit) {
  let times = timesToCompare(time)
  Object.keys(times).forEach((key) => {
    let temp = spotToSit["properties"][times[key]]
    if (temp < store.getters.getUserMinComfortTemp) {
      times[key] = "tooCold"
    } else if (temp > store.getters.getUserMaxComfortTemp) {
      times[key] = "tooHot"
    } else {
      times[key] = "comfortable"
    }
  })

  return times
}

export function timesToCompare(currentTime) {
  const time = currentTime.split("-")[1]
  const hour = parseInt(time.slice(0, 2))
  const minute = parseInt(time.slice(2, 4))

  if (hour >= 23) {
    return {
      0: currentTime,
      15: `hr-0015`,
      30: `hr-0030`,
      45: `hr-0045`,
      60: `hr-0100`,
    }
  }

  const times = {
    0: currentTime,
    15: `hr-${(hour + Math.floor((minute + 15) / 60))
      .toString()
      .padStart(2, "0")}${((minute + 15) % 60).toString().padStart(2, "0")}`,
    30: `hr-${(hour + Math.floor((minute + 30) / 60))
      .toString()
      .padStart(2, "0")}${((minute + 30) % 60).toString().padStart(2, "0")}`,
    45: `hr-${(hour + Math.floor((minute + 45) / 60))
      .toString()
      .padStart(2, "0")}${((minute + 45) % 60).toString().padStart(2, "0")}`,
    60: `hr-${(hour + Math.floor((minute + 60) / 60))
      .toString()
      .padStart(2, "0")}${((minute + 60) % 60).toString().padStart(2, "0")}`,
  }

  return times
}

// if (
//   utciCurrent >= 9 &&
//   utciCurrent <= 26 &&
//   utciNext15 >= 9 &&
//   utciNext15 <= 26 &&
//   utciNext30 >= 9 &&
//   utciNext30 <= 26 &&
//   utciNext45 >= 9 &&
//   utciNext45 <= 26 &&
//   utciNext60 >= 9 &&
//   utciNext60 <= 26
// ) {
//   d.properties.isComfortableForTheNextSixtyMin = true
// } else if (
//   utciCurrent >= 9 &&
//   utciCurrent <= 26 &&
//   utciNext15 >= 9 &&
//   utciNext15 <= 26 &&
//   utciNext30 >= 9 &&
//   utciNext30 <= 26 &&
//   utciNext45 >= 9 &&
//   utciNext45 <= 26 &&
//   (utciNext60 < 9 || utciNext60 > 26)
// ) {
//   d.properties.isComfortableForTheNextFortyFiveMin = true
// } else if (
//   (utciCurrent >= 9 && utciCurrent) <= 26 &&
//   (utciNext15 >= 9 && utciNext15) <= 26 &&
//   (utciNext30 >= 9 && utciNext30) <= 26 &&
//   (utciNext45 < 9 || utciNext45) > 26
// ) {
//   d.properties.isComfortableForTheNextThirtyMin = true
// } else if (
//   (utciCurrent >= 9 && utciCurrent) <= 26 &&
//   (utciNext15 >= 9 && utciNext15) <= 26 &&
//   (utciNext30 < 9 || utciNext30) > 26
// ) {
//   d.properties.isComfortableForTheNextFifteenMin = true
// } else if (
//   (utciCurrent < 9 || utciCurrent > 26) &&
//   (utciNext15 < 9 || utciNext15 > 26) &&
//   utciNext30 >= 9 &&
//   utciNext30 <= 26
// ) {
//   d.properties.isUncomfortableForTheNextFifteenMin = true
// } else if (
//   (utciCurrent < 9 || utciCurrent > 26) &&
//   (utciNext15 < 9 || utciNext15 > 26) &&
//   (utciNext30 < 9 || utciNext30 > 26) &&
//   utciNext45 >= 9 &&
//   utciNext45 <= 26
// ) {
//   d.properties.isUncomfortableForTheNextThirtyMin = true
// } else if (
//   (utciCurrent < 9 || utciCurrent > 26) &&
//   (utciNext15 < 9 || utciNext15 > 26) &&
//   (utciNext30 < 9 || utciNext30 > 26) &&
//   (utciNext45 < 9 || utciNext45 > 26) &&
//   utciNext60 >= 9 &&
//   utciNext60 <= 26
// ) {
//   d.properties.isUncomfortableForTheNextFortyFiveMin = true
// } else if (
//   (utciCurrent < 9 || utciCurrent > 26) &&
//   (utciNext15 < 9 || utciNext15 > 26) &&
//   (utciNext30 < 9 || utciNext30 > 26) &&
//   (utciNext45 < 9 || utciNext45 > 26) &&
//   (utciNext60 < 9 || utciNext60 > 26)
// ) {
//   d.properties.isUncomfortableForTheNextSixtyMin = true
// }
