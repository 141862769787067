<template>
  <div id="about-page" class="container">
    <div class="row mt-4">
      <div class="col-10 mx-auto">
        <div class="row mt-4 mb-4">
          <div class="col-12 desktop-heading-m">
            Comfort NYC
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-2 global-text-l-regular">
            Comfort NYC is a collaborative project among KPF’s Design Technology Team to convey the benefit of microclimate analysis in Urban Environments. We are currently using our beloved Bryant Park as a case study, but we plan to expand this methodology to other areas of the city.
          </div>
          <div class="col-12 mb-2 global-text-l-regular">
            A common question we ask each other is, “Would you like to have lunch in Bryant Park?” With this application, you will be able to determine not only if the weather is nice enough to sit outside but also which areas of the park are the most comfortable at various times throughout the day.
          </div>
          <div class="col-12 mb-4 global-text-l-regular">
            We hope this tool benefits the members of our office and the greater community of New York City.
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 my-4 desktop-heading-s">
            Methodology
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-3 global-text-l-regular">
            Comfort NYC leverages several processes that our team uses frequently and combines them into an easy-to-use web application. To display this information accurately, we need to calculate the Universal Thermal Climate Index (UTCI) across the park in an evenly-spaced grid and then map that information spatially for viewing through our application.
          </div>
          <div class="col-12 mb-4 global-text-l-regular">
            To get the UTCI, we ran an analysis of the yearly impact of wind on the local environment using a detailed 3D model of Bryant Park and the surrounding areas. We also ran a simulation of solar radiation for the same period of a full year using the same detailed model. Next, we are able to query the weather data for each day to understand how daily changes impact thermal comfort. This data is then combined and visualized into an easy-to-use interface.
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 my-4 desktop-heading-s">
            Data Sources
          </div>
          <ul>
            <li v-for="(source, index) in dataSourcesAlpha" :key="index">
              <a :href="source.link" target="_blank">{{ source.name }}</a>
            </li>
          </ul>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 text-center global-text-s-regular mt-4">
            <div>
              built by<br /> 
            </div>
            <div class="my-2">
              <a href="https://ui.kpf.com" target="_blank"><b-img :src="kpf_logo" /></a>
            </div>
            <div>© 2024 Kohn Pedersen Fox Associates</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex'

export default {
  name: "AboutPage",
  data() {
    return {
      kpf_logo: require("@/assets/kpf_logo.png"),
      dataSources: [
        { name: "National Weather Data", link: " https://www.weather.gov/" },
        { name: "Current Weather Data", link: "https://openweathermap.org/current" },
        { name: "Weather Icons", link: "https://erikflowers.github.io/weather-icons/" },
        { name: "Mapbox", link: "https://www.mapbox.com/" },
        { name: "Vue.js", link: "https://v2.vuejs.org/" }
      ]
    }
  },
  computed: {
    ...mapGetters({
      'mobileFlag': "getMobileFlag"
    }),
    dataSourcesAlpha() {
      let d = this.dataSources
      return d.sort((a, b) => {
        const ta = a.name.toUpperCase(),
          tb = b.name.toUpperCase();

        return (ta < tb) ? -1 : (ta > tb) ? 1 : 0;
      })
    }
  }
}
</script>
  
<style lang="scss" scoped>
#aboutPage {
  p {
    text-align: justify;
  }
}

img {
  height: 25px;
  width: auto;
}

.background {
  background-color: rgb(26, 26, 26);
}

.spacer-sm {
  height: 2rem;
}
.spacer-md {
  height: 4rem;
}
</style>

