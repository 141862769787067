var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dataType === 'comfortBreakdown')?_c('monthlyColors'):_vm._e(),(_vm.dataType === 'comfortLevel')?_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.dataType === 'comfortDuration')?_c('div',[_vm._m(4),_vm._m(5)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"comfort-levels"},[_c('div',{staticClass:"comfort-level"},[_vm._v("EXTREME COLD STRESS")]),_c('div',{staticClass:"comfort-level"},[_vm._v("STRONG COLD STRESS")]),_c('div',{staticClass:"comfort-level"},[_vm._v("MODERATE COLD STRESS")]),_c('div',{staticClass:"comfort-level"},[_vm._v("SLIGHT COLD STRESS")]),_c('div',{staticClass:"comfort-level"},[_vm._v("NO THERMAL STRESS")]),_c('div',{staticClass:"comfort-level"},[_vm._v("MODERATE HEAT STRESS")]),_c('div',{staticClass:"comfort-level"},[_vm._v("STRONG HEAT STRESS")]),_c('div',{staticClass:"comfort-level"},[_vm._v("EXTREME HEAT STRESS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"usercolorbar"},[_c('div',{staticClass:"color-bar"},[_c('div',{staticClass:"color-block first"}),_c('div',{staticClass:"color-block second"}),_c('div',{staticClass:"color-block third"})]),_c('div',{staticClass:"lines"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"line"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-comfort-temps"},[_c('div',{staticClass:"comfort-temp"}),_c('div',{staticClass:"comfort-temp"},[_vm._v("-17℉")]),_c('div',{staticClass:"comfort-temp"},[_vm._v("9℉")]),_c('div',{staticClass:"comfort-temp"},[_vm._v("32℉")]),_c('div',{staticClass:"comfort-temp"},[_vm._v("48℉")]),_c('div',{staticClass:"comfort-temp"},[_vm._v("79℉")]),_c('div',{staticClass:"comfort-temp"},[_vm._v("90℉")]),_c('div',{staticClass:"comfort-temp"},[_vm._v("100℉")]),_c('div',{staticClass:"comfort-temp"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"comfort-descriptions"},[_c('div',{staticClass:"comfort-description left"},[_vm._v("TOO COLD")]),_c('div',{staticClass:"comfort-description center"},[_vm._v("JUST RIGHT")]),_c('div',{staticClass:"comfort-description right"},[_vm._v("HOT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"color-bars"},[_c('div',{staticClass:"color-block duration",staticStyle:{"background-color":"#F5B3F5"}}),_c('div',{staticClass:"color-block duration",staticStyle:{"background-color":"#D680D6"}}),_c('div',{staticClass:"color-block duration",staticStyle:{"background-color":"#AD4DAD"}}),_c('div',{staticClass:"color-block duration",staticStyle:{"background-color":"#800080"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"duration-times"},[_c('div',{staticClass:"duration-time"},[_vm._v("15 MIN")]),_c('div',{staticClass:"duration-time"},[_vm._v("30 MIN")]),_c('div',{staticClass:"duration-time"},[_vm._v("45 MIN")]),_c('div',{staticClass:"duration-time"},[_vm._v("60 MIN")])])
}]

export { render, staticRenderFns }