<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name : "app",
  created () {
    this.testIsMobile()
    window.addEventListener('resize', this.testIsMobile)
  },
  methods: {
    /**
     * @output
     * returns if the screen is a mobile devive as true or false
     */
    testIsMobile: function () {
      this.$store.commit('setMobileFlag', window.innerWidth < 768)
    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto:300,400,500&display=swap');
// basic html overrides
@import "styles/basics";
// layout
// @import "styles/layout.scss";
// font styling
@import "styles/typography.scss";

// @import "styles/overrides.scss";

body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Roboto','Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $white;
}

</style>
