<template>
  <div class="container mb-4">
      <div class="text-white mb-4 italic text-xs font-medium leading-4">
      Select Time of Day
      </div>
      <vue-slider
        v-model="chosenTime"
        :min="min"
        :max="max"
        :interval="interval"
        :tooltip="'active'"
        :use-keyboard="false"
        :tooltip-formatter="formatter"
        :tooltip-style="{ background: '#fff', color: '#000', borderColor: '#fff' }"
        :process="false"
        :marks="marks"
        :drag-on-click="true"
        @drag-end="updateSlider"
        @error="sliderError"
        class="white-slider mb-3 mx-4" 
      >
      </vue-slider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'timeSlider',
  components: {
    VueSlider
  },
  props:{},
  computed: {
    chosenTime: {
      get() {
        return this.$store.getters.getChosenTime;
      },
      set(value) {
        this.$store.commit('setChosenTime', value);
      }
    }
  },
  data() {
    return {
      min: 0,
      max: 23.75,
      interval: .25,
      formatter: (value) => {
        const hours = Math.floor(value);
        const minutes = (value % 1) * 60;

        if (minutes === 0) {
          return `${hours}:00`;
        } else {
          return `${hours}:${minutes}`;
        }
      },
      marks: {
        0: {
          label: '0:00'
        },
        6: {
          label: '6:00'
        },
        12: {
          label: '12:00'
        },
        18: {
          label: '18:00'
        },
        23.75: {
          label: '24:00'
        },
      },
    }
  },
  methods: {
    updateSlider() {
      this.$root.$emit('timeUpdate')
    },
    sliderError() {
      console.log('error')
    },
    unformatter(value) {
      if (typeof value === 'string') {
          if (value.length !== 4) {
          throw new Error('Invalid time string format. Expected "HHMM".')
        }

        const hour = parseInt(value.slice(0, 2), 10)
        const min = parseInt(value.slice(2), 10)
        const minDecimal = min / 60

        const decimalTime = hour + minDecimal

        return decimalTime

      
      } else {
        return value;
      }
    }
  }
}
</script>

<style lang="scss">
.white-slider .vue-slider-rail {
  background-color: #fff !important;
}
.vue-slider-rail {
  height: 2px;
}

</style>