<template id="switchButton">
  <div class="switch-button-control">
    <div class="switch-button-label text-left align-self-start">
      <slot></slot>
    </div>
    <div class="switch-button align-self-center" :class="{ 'enabled': isEnabled }" @click="toggle">
      <div class="button"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'switchButton',
  model: {
    prop: 'isEnabled',
    event: 'toggle'
  },
  props: {
    isEnabled: Boolean,
    dataName: String
  },
  methods: {
    toggle: function () {
      this.$emit('toggle', !this.isEnabled)
    }
  }
}
</script>

<style lang="scss">

.switch-button-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .switch-button {
    $switch-button-height: 1.2em;
    $switch-button-color: $white;
    $switch-button-border-thickness: 1px;
    $switch-transition: all 0.3s ease-in-out;
    $switch-is-rounded: true;

    height: $switch-button-height;
    width: calc(#{$switch-button-height} * 2);
    // border: $switch-button-border-thickness solid $switch-button-color;
    // box-shadow: inset 0px 0px $switch-button-border-thickness 0px
    //   rgba(0, 0, 0, 0.33);
    border-radius: if($switch-is-rounded, $switch-button-height, 0);
    background-color: $dark-grayscale-light-gray;

    transition: $switch-transition;
    padding: .2em;

    $button-side-length: calc(
      #{$switch-button-height} - (2 * #{$switch-button-border-thickness})
    );

    cursor: pointer;

    .button {
      height: calc(#{.90em} - (2 * #{$switch-button-border-thickness}));
      width: calc(#{.90em} - (2 * #{$switch-button-border-thickness}));
      // border: $switch-button-border-thickness solid $switch-button-color;
      border-radius: if($switch-is-rounded, $button-side-length, 0);
      background: $switch-button-color;

      transition: $switch-transition;

      // &:hover{
      //   background: $black;
      //   border-color: $black;
      // }
    }

    &.enabled {
      // background-color: $switch-button-color;
      background-color: $white;
      box-shadow: none;
      border: 0px;

      .button {
        background: $dark-grayscale-light-gray;
        transform: translateX(
          calc(#{$button-side-length} + (2 *#{$switch-button-border-thickness}))
        );
      }
    }
  }

  .switch-button-label {
    margin-left: 0px;

    span{
      opacity: 0.54;
    }
  }
}
</style>
