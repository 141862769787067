var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('vue-slider',{staticClass:"white-slider mb-3 px-4",attrs:{"range":"","min":_vm.min,"max":_vm.max,"interval":_vm.interval,"tooltip":'active',"use-keyboard":false,"tooltip-formatter":_vm.formatter,"tooltip-style":{ background: '#fff', color: '#000', borderColor: '#fff' },"process":true,"marks":_vm.marks,"drag-on-click":true,"process-style":{
        backgroundColor: '#fff',
        height: '4px'
      }},on:{"drag-end":_vm.updateSlider,"error":_vm.sliderError},scopedSlots:_vm._u([{key:"process",fn:function({ start, end }){return [_c('div',{staticClass:"vue-slider-process",style:({
          left: `${start}%`,
          width: `${end - start}%`,
          backgroundColor: '#fff',
          height: '6px',
          marginTop: '-2px'
        })})]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }