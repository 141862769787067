<template>
    <div>
      <!-- <div> 
        <div style="width: 33%; height: 16px; display: inline-block; text-align: left; font-family: 'Roboto Mono', monospace; opacity:0.5; font-size: 12px">slightly cold</div>
        <div style="width: 33%; height: 16px; display: inline-block; text-align: center; font-family: 'Roboto Mono', monospace; opacity:0.5; font-size: 12px">comfortable</div>
        <div style="width: 33%; height: 16px; display: inline-block; text-align: right; font-family: 'Roboto Mono', monospace; opacity:0.5; font-size: 12px">slightly hot</div>
      </div> -->
      <div :style="gradientStyle" class="gradient-bar"></div>
      <div class="temperature-bar">
        <div v-for="(item, index) in gradientTemps" :key="index" class="temperature-item">
          {{ item.F }}&#8457;
        </div>
      </div>
        <!-- <div style="width: 10%; height: 16px; display: inline-block; text-align: left;"></div>
        <div style="width: 14%; height: 16px; display: inline-block; text-align: left; font-family: 'Roboto Mono', monospace; opacity:0.5; font-size: 12px">5&#8451;</div>
        <div style="width: 14%; height: 16px; display: inline-block; text-align: left; font-family: 'Roboto Mono', monospace; opacity:0.5; font-size: 12px">9&#8451;</div>
        <div style="width: 14%; height: 16px; display: inline-block; text-align: left; font-family: 'Roboto Mono', monospace; opacity:0.5; font-size: 12px">14&#8451;</div>
        <div style="width: 14%; height: 16px; display: inline-block; text-align: left; font-family: 'Roboto Mono', monospace; opacity:0.5; font-size: 12px">18&#8451;</div>
        <div style="width: 14%; height: 16px; display: inline-block; text-align: left; font-family: 'Roboto Mono', monospace; opacity:0.5; font-size: 12px">21&#8451;</div>
        <div style="width: 14%; height: 16px; display: inline-block; text-align: left; font-family: 'Roboto Mono', monospace; opacity:0.5; font-size: 12px">26&#8451;</div> -->
    </div>
</template>

<script>
export default {
  name: 'monthlyColors',
  computed: {
    currentMonth() {
      return new Date().getMonth(); // January is 0, December is 11
    },
    gradientColors() {
      const monthlyColors = {
        0: ['#662DF0', '#2D47F0', '#2D9BF0', '#2DEFF0', '#2DF09E', '#06A533'], // January
        1: ['#662DF0', '#2D47F0', '#2D9BF0', '#2DEFF0', '#2DF09E', '#06A533', '#B7F02D'], // February
        2: ['#662DF0', '#2D47F0', '#2D9BF0', '#2DEFF0', '#2DF09E', '#06A533', '#B7F02D'], // March
        3: ['#2D9BF0', '#2DEFF0', '#2DF09E', '#06A533', '#B7F02D', '#F0D62D'], // April
        4: ['#2DEFF0', '#2DF09E', '#06A533', '#B7F02D', '#F0D62D', '#F0822D'], // May
        5: ['#2DF09E', '#06A533', '#B7F02D', '#F0D62D', '#F0822D', '#F02E2D'], // June
        6: ['#06A533', '#B7F02D', '#F0D62D', '#F0822D', '#F02E2D'], // July
        7: ['#06A533', '#B7F02D', '#F0D62D', '#F0822D', '#F02E2D'], // August
        8: ['#2DEFF0', '#2DF09E', '#06A533', '#B7F02D', '#F0D62D', '#F0822D', '#F02E2D'], // September
        9: ['#2D9BF0', '#2DEFF0', '#2DF09E', '#06A533', '#B7F02D', '#F0D62D', '#F0822D'], // October
        10: ['#2D47F0', '#2D9BF0', '#2DEFF0', '#2DF09E', '#06A533', '#B7F02D'], // November
        11: ['#662DF0', '#2D47F0', '#2D9BF0', '#2DEFF0', '#2DF09E', '#06A533']  // December
      };

      return monthlyColors[this.currentMonth];
    },
    gradientStyle() {
      const gradient = `linear-gradient(to right, ${this.gradientColors.join(', ')})`;
      return {
        background: gradient,
        width: '100%',
        height: '16px'
      };
    },
    gradientTemps() {
      const tempColorKey = {
        '#662DF0': { 'F': 14, 'C': -10 }, 
        '#2D47F0': { 'F': 23, 'C': -5 }, 
        '#2D9BF0': { 'F': 32, 'C': 0 }, 
        '#2DEFF0': { 'F': 41, 'C': 5 }, 
        '#2DF09E': { 'F': 50, 'C': 10 }, 
        '#06A533': { 'F': 59, 'C': 15 }, 
        '#B7F02D': { 'F': 68, 'C': 20 }, 
        '#F0D62D': { 'F': 77, 'C': 25 }, 
        '#F0822D': { 'F': 86, 'C': 30 }, 
        '#F02E2D': { 'F': 95, 'C': 35 }, 
      }
      let temperatures = [];
      this.gradientColors.forEach(color => {
        temperatures.push(tempColorKey[color])
      })
      return temperatures
    },
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.gradient-bar {
  width: 100%; /* Adjust the width as needed */
  height: 16px; /* Adjust the height as needed */
}
.temperature-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.temperature-item {
  height: 16px;
  display: inline-block;
  text-align: left;
  font-family: 'Roboto Mono', monospace;
  opacity: 0.5;
  font-size: 12px;
}
</style>