<template>
  <div class="">
      <vue-slider
        v-model="value"
        range
        :min="min"
        :max="max"
        :interval="interval"
        :tooltip="'active'"
        :use-keyboard="false"
        :tooltip-formatter="formatter"
        :tooltip-style="{ background: '#fff', color: '#000', borderColor: '#fff' }"
        :process="true"
        :marks="marks"
        :drag-on-click="true"
        @drag-end="updateSlider"
        @error="sliderError"
        class="white-slider mb-3 px-4" 
        :process-style="{
          backgroundColor: '#fff',
          height: '4px'
        }"
      >
      <template #process="{ start, end }">
        <div
          class="vue-slider-process"
          :style="{
            left: `${start}%`,
            width: `${end - start}%`,
            backgroundColor: '#fff',
            height: '6px',
            marginTop: '-2px'
          }"
        ></div>
      </template>
      </vue-slider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'comfortSlider',
  components: {
    VueSlider
  },
  props:{},
  data() {
    return {
      value: [this.$store.getters.getUserMinComfortTemp, this.$store.getters.getUserMaxComfortTemp],
      min: 0,
      max: 45,
      interval: 1,
      formatter: (value) => {
        const fahrenheit = (value * 9/5) + 32;
        return `${fahrenheit.toFixed(1)}℉`; 
      },
      marks: {
        0: {
          label: '32℉'
        },
        15: {
          label: '59℉'
        },
        30: {
          label: '86℉'
        },
        45: {
          label: '113℉'
        }
      },
    }
  },
  methods: {
    updateSlider() {
      this.$root.$emit('comfortUpdate', this.value)
    },
    sliderError() {
    }
  }
}
</script>

<style lang="scss">
.white-slider .vue-slider-rail {
  background-color: #fff !important;
}
.vue-slider-rail {
  height: 2px;
}

</style>