import Vue from "vue"
import Vuex from "vuex"
import { json } from "d3-fetch"

// import { interpolateHcl } from "d3-interpolate"
// import { scaleLinear } from 'd3-scale';
import axios from "axios"

import { version } from "../package.json"

Vue.use(Vuex)

const middleLayerUrl = "https://comfortmiddlelayer.kpfui.dev/"
// process.env.NODE_ENV === "development"
//   ? "http://localhost:3000/"
//   : "https://comfortmiddlelayer.kpfui.dev/"

export default new Vuex.Store({
  state: {
    mobileFlag: false,
    meshData: [],
    metric: null,
    activeScale: "comfortLevel",
    version: version || "0",
    setting: "results_settings",
    aboutToggle: false,
    singlePointTimesWithTemperatures: [],
    showPointTemperatureBar: false,
    todaysComfortData: [],
    userMinComfortTemp: 9,
    userMaxComfortTemp: 26,
    chosenTime:
      new Date().getHours() +
      ((Math.round(new Date().getMinutes() / 15) * 0.25) % 1),
  },
  getters: {
    getAboutToggle(state) {
      return state.aboutToggle
    },
    getShowPointTemperatureBar(state) {
      return state.showPointTemperatureBar
    },
    getMetric(state) {
      return state.metric
    },
    getMobileFlag(state) {
      return state.mobileFlag
    },
    getMeshData(state) {
      return state.meshData
    },
    getVersion(state) {
      return state.version
    },
    getSetting(state) {
      return state.setting
    },
    getScale(state) {
      return state.activeScale
    },
    getSinglePointTimesWithTemperatures(state) {
      return state.singlePointTimesWithTemperatures
        .filter((property) => {
          return property[0].startsWith("hr-") && property[0].endsWith("0")
        })
        .map((property) => {
          if (property[1] < 9) {
            property[1] = "#34A6FA"
          } else if (property[1] >= 9 && property[1] <= 26) {
            property[1] = "#22D976"
          } else if (property[1] > 26) {
            property[1] = "#EE3636"
          }
          return property
        })
        .sort((a, b) => {
          // sort by hour
          return a[0].localeCompare(b[0])
        })
    },
    getUserMinComfortTemp(state) {
      return state.userMinComfortTemp
    },
    getUserMaxComfortTemp(state) {
      return state.userMaxComfortTemp
    },
    getChosenTime(state) {
      return state.chosenTime
    },
  },
  mutations: {
    setMetric(state, metric) {
      state.metric = metric
    },
    setScale(state, scale) {
      state.activeScale = scale
    },
    setMobileFlag(state, newMobileFlag) {
      state.mobileFlag = newMobileFlag
    },
    setMeshData(state, newMeshData) {
      state.meshData = newMeshData
    },
    resetMeshData(state, meshData) {
      state.meshData = meshData
    },
    setSetting(state, setting) {
      state.setting = setting
    },
    setAboutToggle(state, newAboutToggle) {
      state.aboutToggle = newAboutToggle
    },
    setSinglePointTimesWithTemperatures(state, timesWithTemperatures) {
      state.singlePointTimesWithTemperatures = timesWithTemperatures
    },
    setShowPointTemperatureBar(state, showPointTemperatureBar) {
      state.showPointTemperatureBar = showPointTemperatureBar
    },
    setUserMinMaxComfortUTI(state, userMinMaxComfortUTI) {
      state.userMinComfortTemp = userMinMaxComfortUTI[0]
      state.userMaxComfortTemp = userMinMaxComfortUTI[1]
    },
    setChosenTime(state, chosenTime) {
      state.chosenTime = chosenTime
    },
  },
  actions: {
    readMeshData(context, payload) {
      if (payload !== null) {
        json("./data/analysis_layers/" + payload, () => {}).then((response) => {
          context.commit("setMeshData", response)
        })
      } else {
        context.commit("setMeshData", {
          type: "FeatureCollection",
          crs: {
            type: "name",
            properties: {
              name: "urn:ogc:def:crs:OGC:1.3:CRS84",
            },
          },
          features: [],
        })
      }
    },
    //fetch data from the server
    async fetchData() {
      const response = await axios.get(
        `${middleLayerUrl}locationtemperaturedata`
      )
      this.todaysComfortData = response.data
      console.log("typeof todaysComfortData", typeof this.todaysComfortData)
      const jsonString = JSON.stringify(this.todaysComfortData, null, 2)
      console.log("startcopyhere")
      console.log(jsonString)
      console.log("endcopyhere")
      return this.todaysComfortData
    },
    //delete this later - just testing the endpoint for gathering data
    async startGatheringData() {
      console.log("seding the gather request")
      const requestData = {
        password: "bryantparklunch",
      }
      const response = await axios.post(
        `${middleLayerUrl}gathertommorrowsdata`,
        requestData
      )
      console.log(response.data)
    },
    //delete this later - just testing the endpoint for gathering data
    async swapData() {
      console.log("seding the swap request")
      const requestData = {
        password: "bryantparklunch",
      }
      const response = await axios.post(
        `${middleLayerUrl}swapdatatonextday`,
        requestData
      )
      console.log(response.data)
    },
  },
})
