import { MeshStandardMaterial } from "three"

export const options = [
  { name: "AOR", item: "0" },
  { name: "Proposed", item: "1" },
  // {name: 'Alternate', item: '1A'},
  // {name: '17-Story', item: '1B'}
]

export const materials = {
  mat1: new MeshStandardMaterial({
    color: 0xffffff,
    roughness: 1,
    metalness: 0.5,
  }),
  mat2: new MeshStandardMaterial({
    color: 0xc9c9c9,
    roughness: 1,
    metalness: 0.5,
  }),
  mat3: new MeshStandardMaterial({
    color: 0x8b9aa1,
    roughness: 1,
    metalness: 0.5,
  }),
  mat4: new MeshStandardMaterial({
    color: 0x4391cc,
    roughness: 1,
    metalness: 0.5,
  }),
}

export const buildingOptions = {
  0: {
    material: materials.mat4,
    model: "0_geometry.gltf",
  },
  1: {
    material: materials.mat4,
    model: "1_geometry.gltf",
  },
  "1A": {
    material: materials.mat4,
    model: "1A_geometry.gltf",
  },
  "1B": {
    material: materials.mat4,
    model: "1B_geometry.gltf",
  },
}

export const models = [
  {
    dataName: "comfortBreakdown",
    toggle: true,
    userInputRequired: false,
    label: "Feels Like Temperature",
    tagline:
      "What does the temperature feel like in different areas of the park?",
    description:
      "‘Feels Like’ temperature, describes how hot or cold it actually feels outside. It takes into account environmental factors that we experience such as direct sunlight exposure, reflected heat from surfaces, diffuse and thermal solar radiation, wind, air temperature, and humidity.",
    option: true,
    results_settings: {
      max: 0.35,
      min: 0.2,
      interval: 0.01,
      values: [0.2, 0.35],
      marks: {
        0.2: "20% days",
        0.35: "35% days",
      },
      gradient: ["#78f0a4", "#ffff01", "#e97422", "#f62b00"],
      mapFilter: [
        "interpolate",
        ["linear"],
        ["number", ["get", "values"]],
        0.2,
        "#78f0a4",
        0.25,
        "#ffff01",
        0.3,
        "#e97422",
        0.35,
        "#f62b00",
      ],
    },
    change_settings: {
      interval: 0.02,
      max: 0,
      min: -0.08,
      values: [-0.08, 0],
      marks: {
        "-0.08": "-29  days/yr",
        0: "0 days/yr",
      },
      gradient: ["#bd0000", "#bd3dff", "#0048ff", "#9ab6ff", "#e3e3e3"],
      mapFilter: [
        "step",
        ["number", ["get", "values"]],
        "#bd0000",
        -0.08,
        "#bd3dff",
        -0.06,
        "#0048ff",
        -0.04,
        "#9ab6ff",
        -0.02,
        "#e3e3e3",
      ],
    },
    DLI23: {
      0: {
        change: "BBG_DLI23_0_change.json",
        results: "BBG_DLI23_0_results.json",
      },
      1: {
        change: "BBG_DLI23_1_change.json",
        results: "BBG_DLI23_1_results.json",
      },
      "1A": {
        change: "BBG_DLI23_1A_change.json",
        results: "BBG_DLI23_1A_results.json",
      },
      "1B": {
        change: "BBG_DLI23_1B_change.json",
        results: "BBG_DLI23_1B_results.json",
      },
    },
  },
  {
    dataName: "comfortLevel",
    userInputRequired: true,
    toggle: false,
    label: "Effective Comfort Level",
    tagline: "How comfortable are different spots in the park?",
    description:
      "Effective Comfort Level displays areas of the park with optimal thermal comfort, segmented into 15 minute intervals based on the UTCI value.",
    option: true,
    results_settings: {
      max: 5,
      min: 0,
      interval: 1,
      values: [0, 5],
      marks: {
        0: "0 hr/day",
        5: "5 hr/day",
      },
      gradient: ["#3d3d3d", "#7da55e", "#8ee8ab", "#f7eb57", "#feaf46"],
      mapFilter: [
        "interpolate",
        ["linear"],
        ["number", ["get", "values"]],
        0,
        "#3d3d3d",
        2,
        "#7da55e",
        3,
        "#8ee8ab",
        5,
        "#f7eb57",
      ],
    },
    change_settings: {
      interval: 0.1,
      max: 0,
      min: -1.5,
      values: [0, -1.5],
      marks: {
        "-1.5": "-1.5 hr/day",
        0: "0 hr/day",
      },
      gradient: ["#000000", "#595959", "#9ab6ff", "#e3e3e3"],
      mapFilter: [
        "step",
        ["number", ["get", "values"]],
        "#000000",
        -1.5,
        "#595959",
        -1.0,
        "#9ab6ff",
        -0.6,
        "#e3e3e3",
      ],
    },
    DLAvg: {
      0: {
        change: "BBG_DLAvg_0_change.json",
        results: "BBG_DLAvg_0_results.json",
      },
      1: {
        change: "BBG_DLAvg_1_change.json",
        results: "BBG_DLAvg_1_results.json",
      },
      "1A": {
        change: "BBG_DLAvg_1A_change.json",
        results: "BBG_DLAvg_1A_results.json",
      },
      "1B": {
        change: "BBG_DLAvg_1B_change.json",
        results: "BBG_DLAvg_1B_results.json",
      },
    },
  },
  {
    dataName: "comfortDuration",
    userInputRequired: true,
    toggle: false,
    label: "Projected Comfort Duration",
    tagline: "How long will each spot in the park remain comfortable?",
    description:
      "Projected Comfort Duration indicates the time a location remains thermally comfortable or becomes uncomfortable.",
    option: true,
    results_settings: {
      max: 0.35,
      min: 0.2,
      interval: 0.01,
      values: [0.2, 0.35],
      marks: {
        0.2: "20% days",
        0.35: "35% days",
      },
      gradient: ["#37c163", "#c7f944", "#ea7020", "#f62b00"],
      mapFilter: [
        "interpolate",
        ["linear"],
        ["number", ["get", "values"]],
        0.2,
        "#37c163",
        0.25,
        "#c7f944",
        0.3,
        "#ea7020",
        0.35,
        "#f62b00",
      ],
    },
    change_settings: {
      interval: 0.02,
      max: 0,
      min: -0.08,
      values: [-0.08, 0],
      marks: {
        "-0.08": "-29  days/yr",
        0: "0 days/yr",
      },
      gradient: ["#bd0000", "#bd3dff", "#0048ff", "#9ab6ff", "#e3e3e3"],
      mapFilter: [
        "step",
        ["number", ["get", "values"]],
        "#bd0000",
        -0.08,
        "#bd3dff",
        -0.06,
        "#0048ff",
        -0.04,
        "#9ab6ff",
        -0.02,
        "#e3e3e3",
      ],
    },
    DLI23: {
      0: {
        change: "BBG_DLI23_0_change.json",
        results: "BBG_DLI23_0_results.json",
      },
      1: {
        change: "BBG_DLI23_1_change.json",
        results: "BBG_DLI23_1_results.json",
      },
      "1A": {
        change: "BBG_DLI23_1A_change.json",
        results: "BBG_DLI23_1A_results.json",
      },
      "1B": {
        change: "BBG_DLI23_1B_change.json",
        results: "BBG_DLI23_1B_results.json",
      },
    },
  },
]
