<template>
  <div @mouseover="showPopup" @mouseleave="hidePopup">
    <div class="spacer"></div>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="17" viewBox="0 0 16 17" fill="none">
      <rect width="18" height="17" fill="transparent"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3996 8.49998C14.3996 12.0346 11.5342 14.9 7.99961 14.9C4.46499 14.9 1.59961 12.0346 1.59961 8.49998C1.59961 4.96535 4.46499 2.09998 7.99961 2.09998C11.5342 2.09998 14.3996 4.96535 14.3996 8.49998ZM8.79961 5.29998C8.79961 5.7418 8.44144 6.09998 7.99961 6.09998C7.55778 6.09998 7.19961 5.7418 7.19961 5.29998C7.19961 4.85815 7.55778 4.49998 7.99961 4.49998C8.44144 4.49998 8.79961 4.85815 8.79961 5.29998ZM7.19961 7.69998C6.75778 7.69998 6.39961 8.05815 6.39961 8.49998C6.39961 8.9418 6.75778 9.29998 7.19961 9.29998V11.7C7.19961 12.1418 7.55778 12.5 7.99961 12.5H8.79961C9.24144 12.5 9.59961 12.1418 9.59961 11.7C9.59961 11.2581 9.24144 10.9 8.79961 10.9V8.49998C8.79961 8.05815 8.44144 7.69998 7.99961 7.69998H7.19961Z" fill="white"/>
    </svg>
    <info-popup ref="popup" :message="message"/>
  </div>
</template>

<script>
import infoPopup from '@/components/infoPopup.vue';

export default {
  components: {
    infoPopup
  },
  props: {
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    showPopup() {
      this.$refs.popup.show();
    },
    hidePopup() {
      this.$refs.popup.hide();
    },
    showMessage() {
      console.log(this.message);
    }
  }
};
</script>

<style>
.icon-container {
  position: relative;
  display: inline-block;
  overflow: visible;
}
</style>