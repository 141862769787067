<template>
  <div>
    <monthlyColors v-if="dataType === 'comfortBreakdown'" />
    <div v-if="dataType === 'comfortLevel'">
      <div class="comfort-levels">
        <div class="comfort-level">EXTREME COLD STRESS</div>
        <div class="comfort-level">STRONG COLD STRESS</div>
        <div class="comfort-level">MODERATE COLD STRESS</div>
        <div class="comfort-level">SLIGHT COLD STRESS</div>
        <div class="comfort-level">NO THERMAL STRESS</div>
        <div class="comfort-level">MODERATE HEAT STRESS</div>
        <div class="comfort-level">STRONG HEAT STRESS</div>
        <div class="comfort-level">EXTREME HEAT STRESS</div>
      </div>
      <div class="usercolorbar">
        <div class="color-bar">
          <div class="color-block first"></div>
          <div class="color-block second"></div>
          <div class="color-block third"></div>
        </div>
        <div class="lines">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>
      <div class="user-comfort-temps">
        <div class="comfort-temp"></div>
        <div class="comfort-temp">-17&#8457;</div>
        <div class="comfort-temp">9&#8457;</div>
        <div class="comfort-temp">32&#8457;</div>
        <div class="comfort-temp">48&#8457;</div>
        <div class="comfort-temp">79&#8457;</div>
        <div class="comfort-temp">90&#8457;</div>
        <div class="comfort-temp">100&#8457;</div>
        <div class="comfort-temp"></div>
      </div>
      <div class="comfort-descriptions">
        <div class="comfort-description left">TOO COLD</div>
        <div class="comfort-description center">JUST RIGHT</div>
        <div class="comfort-description right">HOT</div>
      </div>
    </div>
    <div v-if="dataType === 'comfortDuration'">
      <div class="color-bars">
        <div class="color-block duration" style="background-color: #F5B3F5;"></div>
        <div class="color-block duration" style="background-color: #D680D6;"></div>
        <div class="color-block duration" style="background-color: #AD4DAD;"></div>
        <div class="color-block duration" style="background-color: #800080;"></div>
      </div>
      <div class="duration-times">
        <div class="duration-time">15 MIN</div>
        <div class="duration-time">30 MIN</div>
        <div class="duration-time">45 MIN</div>
        <div class="duration-time">60 MIN</div>
      </div>
    </div>
  </div>
</template>

<script>
import monthlyColors from './monthlyColors.vue'
export default {
  props: {
    dataType: String
  },
  components: {
    monthlyColors
  }
}
</script>

<style lang="scss" scoped>
  .comfort-levels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px; /* Add space below comfort-levels */
    padding-bottom: 15px;
  }
  
  .comfort-level {
    width: 12.5%;
    height: 16px;
    display: inline-block;
    text-align: center;
    font-family: 'Roboto Mono', monospace;
    opacity: 0.5;
    font-size: 8.5px;
  }

  .usercolorbar {
    position: relative;
    width: 100%;
    margin-top: 12px; /* Add space above usercolorbar */
  }

  .color-bar {
    display: flex;
    height: 12px;
    width: 100%;
  }

  .color-block {
    height: 100%;
  }

  .color-block.first {
    background-color: #34A6FA;
    width: 51%;
  }

  .color-block.second {
    background-color: #22D976;
    width: 34%;
  }

  .color-block.third {
    background-color: #EE3636;
    width: 15%;
  }

  .lines {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .user-comfort-temps {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
  }

  .comfort-temp {
    height: 16px;
    display: inline-block;
    text-align: center;
    font-family: 'Roboto Mono', monospace;
    opacity: 0.5;
    font-size: 9.5px;
  }

  .comfort-descriptions {
    display: flex;
    justify-content: space-between;
  }

  .comfort-description {
    height: 16px;
    display: inline-block;
    font-family: 'Roboto Mono', monospace;
    opacity: 0.5;
    font-size: 13px;
  }

  .comfort-description.left {
    text-align: left;
    width: 33%;
  }

  .comfort-description.center {
    text-align: center;
    width: 33%;
  }

  .comfort-description.right {
    text-align: right;
    width: 33%;
  }

  .comfort-duration {
    display: flex;
    justify-content: space-between;
  }

  .duration-text {
    height: 16px;
    display: inline-block;
    font-family: 'Roboto Mono', monospace;
    opacity: 0.5;
    font-size: 12px;
  }

  .duration-text.left {
    text-align: left;
    width: 45%;
  }

  .duration-text.right {
    text-align: right;
    width: 50%;
  }

  .color-bars {
    display: flex;
  }

  .color-block.duration {
    width: 25%;
    height: 16px;
    display: inline-block;
  }

  .duration-times {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .duration-time {
    width: 25%;
    height: 16px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto Mono', monospace;
    opacity: 0.5;
    font-size: 12px;
  }

  .duration-footer {
    display: flex;
    justify-content: space-between;
  }

  .duration-empty {
    width: 45%;
    height: 16px;
    display: inline-block;
    text-align: left;
  }

  .duration-units {
    width: 50%;
    height: 16px;
    display: inline-block;
    text-align: right;
  }

  .line {
    background-color: #000; /* You can change the color if needed */
    width: 2px;
    height: 12px;
  }
</style>