<template>
  <div class="Map">
    <div id="map-container">
      <MglMap :accessToken="accessToken" :mapStyle.sync="mapStyle" container="map-parent" @load="onMapLoaded" :zoom="zoom"
        :center="center" :pitch="pitch" :bearing="bearing">
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" />
      </MglMap>
    </div>

    <div v-if="mobileFlag" class="mobile-bar d-flex justify-content-around align-items-center">
      <div @click="changeToggle(index)" class="mobile-toggles" v-for="(toggle, index) in mobileToggles" :key="index">
        <div class="d-flex justify-content-center align-items-center">
          <mobileIcons :iconType="toggle.iconType" :active="toggle.active" />
        </div>
        <div class="subtitle">{{ toggle.name }}</div>
      </div>
    </div>
    
    <div id="weather-bar-container">
      <weatherBar />
    </div>
    <div id="tooltip-temperature-container">
      <pointforcast @remove-map-marker="removeMapMarker"/>
    </div>
    
    

    <transition name="slide">
      <div v-if="mobileToggles[0].active === true">
        <sidebar @setMapFilter="setMapFilter" @setActiveOption="setActiveOption" />
      </div>
    </transition>
    <transition name="slide">
      <div id="about-container" class="frosted-glass mobile" v-if="mobileFlag === true && aboutToggle === true">
        <aboutPage />
      </div>
    </transition>
    <transition name="slide">
      <div id="about-container" class="frosted-glass desktop" v-if="mobileFlag === false && aboutToggle === true">
        <b-btn class="about-close" @click="aboutClick">X</b-btn>
        <aboutPage />
      </div>
    </transition>
    <loading :loadingFlag="loadingFlag" />
  </div>
</template>

<script>
// IMPORT MAPBOX FUNCTIONS AND TEMPLATES
import Mapbox from 'mapbox-gl'

import {
  MglMap,
  MglNavigationControl,
  MglGeolocateControl
} from 'vue-mapbox'

// mapbox containers
var map = null

import { mapGetters } from 'vuex'

import sidebar from "@/components/sidebar2.vue";
import weatherBar from '../components/weatherBar.vue';
import pointforcast from '../components/toolTipSidebar.vue';

import aboutPage from "@/views/aboutPage.vue";
import mobileIcons from "@/components/mobile_icons.vue";
import loading from "@/components/loading.vue";

//import comfortDataApril from "../../public/data/files/comfortDataApril.json";

/**
 * import THREEjs library
 */
const THREE = require('three')
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const loader = new GLTFLoader();
let scene, camera, renderer = null

//let comfortDataApril = {}

// parameters to ensure the model is georeferenced correctly on the map
let modelOrigin = [-73.959389, 40.666353];
let modelAltitude = 0;
let modelRotate = [Math.PI / 2, 0, 0];

let modelTransform = {}


import { buildingOptions, materials } from '@/assets/template/templates.js'

import { createLights } from '@/assets/utility/methods.js'
import mapboxGl from 'mapbox-gl';

import { comfortDuration } from '../assets/utility/comfortDurationMethods'

export default {
  name: 'Map',
  components: {
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    sidebar,
    mobileIcons,
    aboutPage,
    loading,
    weatherBar,
    pointforcast
},
  data(){
    return {
      accessToken: 'pk.eyJ1Ijoia3BmdWkiLCJhIjoiY2p6MWcxMXl4MDFlbTNsbDc1bnp6N3FjYSJ9.66qFOXwI661MOPOf7x96yA',
      mapStyle: 'mapbox://styles/kpfui/clqnytzjx003701qvf72hdw1x',
      zoom: 16.75,
      center: [-73.98418570372685, 40.75400520139699], 
      loadingFlag: true,
      pitch: 0, // pitch in degrees
      bearing: 29.5, // bearing in degrees

      activeLayer: null,
      activeScale: 'comfortBreakdown',
      contextBBGFlag: true,
      iconsOnMap: [],
      mobileToggles: [
        {
          name: 'explore',
          iconType: 'tool',
          active: true
        },
        {
          name: 'about',
          iconType: "info",
          active: false
        }
      ],
    }
  },
  async created() {
    this.mapbox = Mapbox
    this.subscribeToStore()
    await this.$store.dispatch('fetchData')

    camera = new THREE.Camera()
    scene = new THREE.Scene()

    createLights(scene)
    this.addComfortDurationToFeatures()
  },
  mounted() {
    
    this.$root.$on('timeUpdate', () => this.changeTime())
    this.$root.$on('comfortUpdate', (temp) => this.changeUserComfortRange(temp))
    //this.calculateComfortDuration()
  },
  destroyed() {
    this.unsubscribeFromStore()
  },
  computed: {
    ...mapGetters({
      'meshData': 'getMeshData',
      "mobileFlag": "getMobileFlag",
      "sliderObject": "getSliderObject",
      'metric': 'getMetric',
      'setting': 'getSetting',
      'scale': 'getScale',
      "aboutToggle": "getAboutToggle",
      "getChosenTime": "getChosenTime",
    })
  },
  methods: {
    changeToggle(index) {

      const activeToggle = this.mobileToggles[index]

      if (activeToggle.active) {
        activeToggle.active = !activeToggle.active
      } else {
        this.mobileToggles.forEach((d) => d.active = false)
        this.mobileToggles[index].active = true
      }

      if (index === 1) {
        this.$store.commit('setAboutToggle', this.mobileToggles[index].active)
      }
    },
    changeTime(){
      if (this.activeScale === 'comfortBreakdown') {
        this.colorLayerComfortLevelBreakdown(`hr-${this.formatTime(this.getChosenTime)}`)
        this.$store.commit('setShowPointTemperatureBar', false)
        this.removeMapMarker()
      } else if (this.activeScale === 'comfortLevel') {
        this.colorLayer(`hr-${this.formatTime(this.getChosenTime)}`)
      } else if (this.activeScale === 'comfortDuration') {
        this.colorLayerComfortDuration(`hr-${this.formatTime(this.getChosenTime)}`)
        this.$store.commit('setShowPointTemperatureBar', false)
        this.removeMapMarker()
      }
    },
    changeUserComfortRange(temp) {
      this.$store.commit('setUserMinMaxComfortUTI', temp)
      this.addComfortDurationToFeatures()
      map.getSource('comfort-data').setData(this.$store.todaysComfortData)
      this.changeTime()
    },
    formatTime(value) {
      if (String(value).length === 4 && !String(value).includes('.')) {
        return value
      }
      let hours = Math.floor(value)
      const minutes = (value % 1) * 60

      if (hours < 10) {
        hours = `0${hours}`
      }

      if (minutes === 0) {
        return `${hours}00`
      } else {
        return `${hours}${minutes}`
      }
    },
    aboutClick() {
      this.$store.commit('setAboutToggle', false)
    },
    flyTo(coords, zoom) {
      map.flyTo({
        center: [coords[0], coords[1]],
        zoom: zoom,
        speed: 1,
        curve: 1,
        easing(t) {
          return t
        }
      })
    },
    async onMapLoaded(event) {
      map = event.map

      this.createMeshDataSource();
      this.add3DContext();
      this.loadingFlag = true;
      await this.$store.dispatch('fetchData')
      this.addComfortDurationToFeatures()

      map.addSource('comfort-data', {
        type: 'geojson',
        data: this.$store.todaysComfortData,
      })

      // map.addSource('location-lines-data', {
      //   type: 'geojson',
      //   data: comfortLineWork,
      // })

      // map.addLayer({
      //   id: 'bryant-park-linework',
      //   source: 'location-lines-data',
      //   type: 'line',
      //   paint: {
      //     'line-color': '#00ffff',
      //     'line-width': 5,
      //   },
      // })

      map.addLayer({
        id: 'weather-data',
        source: 'comfort-data',
        type: 'fill',
        paint: {
          'fill-color': '#ffffff',
          'fill-opacity': 1,
        },
      }, 'bryantparkline')
      map.dragRotate.disable()

      map.on('click', 'weather-data', (e) => {
        if (this.activeScale === 'comfortLevel') {
          this.addLocationIcon(e)
          this.pullUpTemperatureData(e)
        } else {
          this.removeMapMarker()
        }
      })
      this.loadingFlag = false;

      this.$root.$emit('timeUpdate', 12)
    },
    addLocationIcon(e) {
      if (this.iconsOnMap.length > 0) {
        this.iconsOnMap.forEach((d) => d.remove())
      }

      const clickCoordinates = e.features[0].geometry.coordinates.slice();
      const iconLocation = clickCoordinates[0][0]
      console.log('pointId: ', e.features[0].properties["ID"])

      const marker = new mapboxGl.Marker({
        color: '#FFFFFF',
      }).setLngLat(iconLocation)
        .addTo(map);

      this.iconsOnMap.push(marker)
    },
    removeMapMarker() {
      if (this.iconsOnMap.length > 0) {
        this.iconsOnMap.forEach((d) => d.remove())
      }
    },
    pullUpTemperatureData(e) {
      this.$store.commit('setSinglePointTimesWithTemperatures', Object.entries(e.features[0].properties))
      this.$store.commit('setShowPointTemperatureBar', true)
    },
    setActiveOption(option) {

      this.loadingFlag = true;
      /**
       * handles removing current layer
       */
      if (this.activeLayer !== null) {
        let selectedObject = scene.getObjectByName(this.activeLayer);
        scene.remove(selectedObject)

        if (map.getLayer(option)) {
          map.removeLayer(option);
        }
      }

      this.activeLayer = option

      const sel = buildingOptions[option]
      this.add3dLayer(option, `../data/models/${sel.model}`, sel.material)
    },
    createMeshDataSource() {
      map.addSource('mesh-source-data', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': [{
            'type': 'Feature',
            'properties': { 'name': 'Null Island' },
            'geometry': {
              'type': 'Point',
              'coordinates': [0, 0]
            }
          }]
        }
      })

      var layers = map.getStyle().layers;

      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }

      map.addLayer({
        id: 'mesh-polygons',
        source: 'mesh-source-data',
        type: 'fill',
        paint: {}
      }, labelLayerId)
    },
    resetMeshData(meshSourceData) {
      map.getSource('mesh-source-data').setData(meshSourceData)
    },
    addMeshDataToMap(meshSourceData) {

      this.loadingFlag = true;

      if (map.isSourceLoaded('mesh-source-data')) {
        map.getSource('mesh-source-data').setData(meshSourceData)
      }

      map.setPaintProperty('mesh-polygons', 'fill-color', this.metric[this.setting].mapFilter)

      map.setFilter('mesh-polygons', null)

      setTimeout(() => {
        this.loadingFlag = false
      }, 500);
    },
    setMapFilter(sliderValues, max, min) {

      const [from, to] = sliderValues

      if (to == max && from == min) {
        map.setFilter('mesh-polygons', ['all'])
      } else if (to == max) {
        map.setFilter('mesh-polygons', ['all', ['>=', 'values', from]]);
      } else {
        map.setFilter('mesh-polygons', ['all', ['<=', 'values', to]]);
      }
    },
    add3dLayer(layerName, location, material) {

      let vm = this

      map.addLayer({
        id: layerName,
        type: 'custom',
        renderingMode: '3d',
        onAdd: function (map, gl) {
          vm.load3DLayer(map, gl, layerName, location, material)
        },
        render: this.renderLayer
      }, 'waterway-label');
    },
    load3DLayer(map, gl, layerName, location, material) {

      loader.load(location, function (gltf) {

        gltf.scene.name = layerName

        gltf.scene.traverse((child) => {
          if (child.isMesh) child.material = material
        });

        scene.add(gltf.scene);
      }.bind(this)
      );

      // use the Mapbox GL JS map canvas for three.js
      if (renderer === null) {
        renderer = new THREE.WebGLRenderer({
          canvas: map.getCanvas(),
          context: gl,
          antialias: true
        });

        renderer.autoClear = false;
      }

      this.loadingFlag = false;
    },
    renderLayer(gl, matrix) {
      let rotationX = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(1, 0, 0),
        modelTransform.rotateX
      );

      let rotationY = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(0, 1, 0),
        modelTransform.rotateY
      );

      let rotationZ = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(0, 0, 1),
        modelTransform.rotateZ
      );

      let m = new THREE.Matrix4().fromArray(matrix);
      let l = new THREE.Matrix4()

        .makeTranslation(
          modelTransform.translateX,
          modelTransform.translateY,
          modelTransform.translateZ
        )
        .scale(
          new THREE.Vector3(
            modelTransform.scale,
            -modelTransform.scale,
            modelTransform.scale
          )
        )
        .multiply(rotationX)
        .multiply(rotationY)
        .multiply(rotationZ);

      camera.projectionMatrix = m.multiply(l);

      renderer.resetState();
      renderer.render(scene, camera);

      map.triggerRepaint();
    },
    /**
     * adds 3D context parameters
     */
    add3DContext() {

      let modelAsMercatorCoordinate = this.mapbox.MercatorCoordinate.fromLngLat(
        modelOrigin,
        modelAltitude
      );

      // transformation parameters to position, rotate and scale the 3D model onto the map
      modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        /* Since our 3D model is in real world meters, a scale transform needs to be
        * applied since the CustomLayerInterface expects units in MercatorCoordinates.
        */
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
      };

      this.add3dLayer('context', '../data/models/context.gltf', materials.mat1)
      this.add3dLayer('context-bbg', '../data/models/context_bbg.gltf', materials.mat1)
    },
    checkBBGContext(layer) {

      let contextBBGLayerName = 'context-bbg'
      let layers = ['DLI23', 'ShadM06']

      // add layer if not already on and it is not the  layer
      if ((layers.includes(layer) === false) && this.contextBBGFlag === false) {
        this.add3dLayer(contextBBGLayerName, '../data/models/context_bbg.gltf', materials.mat1)

        this.contextBBGFlag = true
      } else if ((layers.includes(layer) === true) && this.contextBBGFlag === true) {
        let selectedObject = scene.getObjectByName(contextBBGLayerName);
        scene.remove(selectedObject)

        if (map.getLayer(contextBBGLayerName)) {
          map.removeLayer(contextBBGLayerName);
        }

        this.contextBBGFlag = false
      }
    },
    subscribeToStore() {
      this.meshDataUnsubscriber = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'setScale':
            this.activeScale = mutation.payload
            this.changeTime()
            break
          case 'setMeshData':

            if (this.meshData.features.length > 0) {
              this.addMeshDataToMap(this.meshData)
              this.checkBBGContext(this.metric.dataName)
            } else {
              this.resetMeshData(this.meshData)
            }

            break
        }
      })
    },
    unsubscribeFromStore() {
      this.meshDataUnsubscriber();
    },
    addComfortDurationToFeatures() {
      this.$store.todaysComfortData.features.forEach((spotToSit) => {
          comfortDuration(spotToSit)
      })
    },
    async colorLayerComfortDuration(time){ 
      const style = map.getStyle();
      const durationKey = `durationSituation-${time}`
      style.layers.find((d) => d.id === 'weather-data').paint['fill-color'] = //check the properties of each feature for one with the key that matches durationKey, if the value is '60-tooCold' #f6fe0c, otherwise color it blue
      // [
      //   'match',
      //   ['get', `${durationKey}`],
      //   '60-tooCold',
      //   '#FF0000',
      //   '60-tooHot',
      //   '#FF0000',
      //   '45-tooCold',
      //   '#FF6666',
      //   '45-tooHot',
      //   '#FF6666',
      //   '30-tooCold',
      //   '#FFCCCC',
      //   '30-tooHot',
      //   '#FFCCCC',
      //   '15-tooCold',
      //   '#FFE6E6',
      //   '15-tooHot',
      //   '#FFE6E6',
      //   '15-comfortable',
      //   '#F0FFF0',
      //   '30-comfortable',
      //   '#C1FFC1',
      //   '45-comfortable',
      //   '#2E8B57',
      //   '60-comfortable',
      //   '#008000',
      //   'tooHot',
      //   '#FF0000',
      //   '#FF6666'
      // ];
      [
        'match',
        ['get', `${durationKey}`],
        '60-tooCold',
        '#212123', // dark purple
        '60-tooHot',
        '#212123', // dark purple
        '45-tooCold',
        '#212123', // medium purple
        '45-tooHot',
        '#212123', // medium purple
        '30-tooCold',
        '#212123', // light purple
        '30-tooHot',
        '#212123', // light purple
        '15-tooCold',
        '#212123', // lighter purple
        '15-tooHot',
        '#212123', // lighter purple
        '15-comfortable',
        '#F5B3F5', // ghost white
        '30-comfortable',
        '#D680D6', // ghost white
        '45-comfortable',
        '#AD4DAD', // ghost white
        '60-comfortable',
        '#800080', // ghost white
        'tooHot',
        '#212123', // dark purple
        '#212123'  // medium purple
      ];
      map.setStyle(style);
    },
    colorLayer(time){
      const style = map.getStyle();
      // style.layers.find((d) => d.id === 'weather-data').paint['fill-color'] = //check the properties of each feature for one with the key of the input time, if the value is less than 9 color it #34A6FA, between 9 and 26 color it #22D976, and if the value is over 26 color it #EE3636
      // [
      //   'interpolate',
      //   ['linear'],
      //   ['get', `${time}`],
      //   -10,
      //   '#0000FA',
      //   17,
      //   '#22D976',
      //   35,
      //   '#EE3636',
      // ];
      // map.setStyle(style);
        style.layers.find((d) => d.id === 'weather-data').paint['fill-color'] = 
        [
          'step',
          ['get', `${time}`],
          '#34A6FA',
          this.$store.getters.getUserMinComfortTemp,
          '#22D976',
          this.$store.getters.getUserMaxComfortTemp,
          '#EE3636'
        ];
        map.setStyle(style);
    },
    colorLayerComfortLevelBreakdown(time){
      const style = map.getStyle();
      if (style) {
        style.layers.find((d) => d.id === 'weather-data').paint['fill-color'] = 
        [
        'interpolate',
          ['linear'],
          ['get', `${time}`],
          -10, '#662DF0', // Value -10
          -5, '#2D47F0',  // Value -5
          0, '#2D9BF0',   // Value 0
          5, '#2DEFF0',   // Value 5
          10, '#2DF09E',  // Value 10
          15, '#06A533',  // Value 15
          20, '#B7F02D',  // Value 20
          25, '#F0D62D',  // Value 25
          30, '#F0822D',  // Value 30
          35, '#F02E2D'
        ];
      map.setStyle(style);
      }
    }
    //this is the last working version of this as of 1/17
    // colorLayerComfortLevelBreakdown(time){
    //   const style = map.getStyle();
    //   if (style) {
    //     style.layers.find((d) => d.id === 'weather-data').paint['fill-color'] = //check the properties of each feature for one with the key of the input time, if the value is between -40 and -27 color it 0000FA, if the value is between -27 and -13 color it 003EE3, if the value is between -13 and 0 color it 0074E3, if the value is between 0 and 9 color it 00D5E3, if the value is between 9 and 26 color it 00E327, if the value is between 26 and 32 color it E3E000, if the value is between 32 and 38 color it E3E000, and if the value is between 38 and 50 color it E33E00
    //   [
    //     'interpolate',
    //     ['linear'],
    //     ['get', `${time}`],
    //     -40,
    //     '#0000FA',
    //     -27,
    //     '#003EE3',
    //     -13,
    //     '#0074E3',
    //     0,
    //     '#00D5E3',
    //     9,
    //     '#00E327',
    //     26,
    //     '#E3E000',
    //     32,
    //     '#E3E000',
    //     38,
    //     '#E33E00',
    //     50,
    //     '#E33E00',
    //     100,
    //     '#E33E00'
    //   ];
    //   map.setStyle(style);
    //   }
    // }
  }
}
</script>

<style lang="scss">
/** vue slide up/down transition */
.slide-enter-active {
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100vh;
  // overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  // overflow: hidden;
  max-height: 0;
}

/* end of vue transition*/



// MAP OPTIONS
#Map {
  width: 100vw;
  height: 100vh;
  // overflow: hidden;
}

.mapboxgl-canvas {
  left: 0;
}

#map-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;

  // overflow: hidden;

  *:focus {
    outline: none;
  }
}

.mapboxgl-popup-content {
  // frosted glass effect
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: rgba(18, 18, 18, 0.85);
  width: 375px;
}

.mapboxgl-popup-close-button {
  color: #ffffff;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.tooltip-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tooltip-list {
  color: $off-white;
  flex-direction: column;
  left: 15px;
  position: relative;
  margin-top: 0.5rem;

  .tooltip-subitem {
    display: flex;


    h5 {
      margin-left: 0.5rem;
    }

    h6 {
      display: list-item;
      list-style-type: disc;
      list-style-position: inside;
    }
  }
}

// ABOUT
.about-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

#about-container {
  position: absolute;
  left: 0;

  background: $black;

  overflow-y: auto;

  top: 0px;
  width: 100%;

  &.mobile {
    height: calc(100vh - 80px);
  }

  &.desktop {
    height: 100vh;
  }

  z-index: 5;
}

#weather-bar-container{
  position: absolute;
  display: flex;
  top: 1.6rem;
  right: 4rem;
  height: 1.5rem;
}

#tooltip-temperature-container{
  position: absolute;
  display: flex;
  top: 4.6rem;
  right: 4rem;
  height: 1.5rem;
}

</style>
